<template>
  <div id="navbar" style="width: 100%;">
  <div class="navbar navbar-container d-flex content align-items-center" v-if="userData">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- Search -->
    <!-- Left Col -->
    <div class="search-result align-items-center flex-grow-1 d-lg-flex">
      <b-nav-item style="list-style-type: none" class="search-nav" >
        <b-form autocomplete="off" name="search">
          <b-input-group class="input-group-merge round">
            <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" width="16" height="16" />
          </b-input-group-prepend>
            <b-form-input id="search-link"  @focus="show=true" @input="onSearch(searchQuery)" @keydown.enter.prevent="handleDown()" name="search-link" placeholder="Search" v-model="searchQuery" />
            <b-input-group-append is-text v-if="searchQuery.length > 0" class="pr-0">
              <feather-icon icon="XIcon" class="text-default" v-b-tooltip.hover.v-dark title="Clear Search" @click="CloseModel()"/>
            </b-input-group-append>
          </b-input-group>
        </b-form>
        <div class="search-down">
          <!-- <b-card-text v-if="searchResult.length > 0 && search_on == 'common' " class="mt-1 px-2">
          <h5>Search Results</h5>
          <b-list-group flush >
            <b-list-group flush>
              <b-list-group-item v-for="Link in searchResult" :key="Link.route" v-if="$can(Link.gate)"> <feather-icon icon="StarIcon" 
                :class="isStarred(Link) ? 'text-primary' : ''" :id="Link.route" style="margin-top:-5px;margin-right:5px;"/><a @click="goTo(Link.route)">{{Link.label}}</a></b-list-group-item>
            </b-list-group>
          </b-list-group>
        </b-card-text>
        <b-tabs v-if="searchQuery.length > 0 && search_on == 'common' " class="px-2 pb-1">
          <b-tab title="All" active>
            <b-row>
              <b-col lg="4" v-for="(LinkSet,LinkSetName) in QuickLinks" :key="LinkSetName">
                <h5 class="text-primary">{{LinkSetName}}</h5>
                <b-list-group flush>
                  <b-list-group-item v-for="(Link,k) in LinkSet" :key="Link.route" v-if="$can(Link.gate)"> <feather-icon icon="StarIcon" 
                    :class="isStarred(Link) ? 'text-primary' : ''" :id="Link.route" style="margin-top:-5px;margin-right:5px;" @click="starred(LinkSetName,k)"/><a @click="goTo(Link.route)">{{Link.label}}</a></b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>          
          </b-tab>
          <b-tab
            title="Starred"
          >
          <b-row>
            <b-col>
              <b-list-group flush>
                <b-list-group-item v-for="route in userData.starred_routes" :key="route.label"> <feather-icon class="text-primary" icon="StarIcon" style="margin-top:-5px;margin-right:5px;"  /> <a @click="goTo(route.route)" > {{route.label}}</a> </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>   
        </b-tab>
      </b-tabs> -->
      <ul v-if="searchResult.length > 0 && show" class="suggestions-list">
        <li 
        v-for="suggestion in searchResult" :key="suggestion.type" @click="search(searchQuery,suggestion.type)">
          {{ suggestion.text }}
        </li>
      </ul>
        </div>
      </b-nav-item>
    </div>
    
      <!-- End -->
    <!-- End Search -->
    <div class="ml-auto mr-2 d-none d-md-flex right-navaction">
      <b-nav-item class="mr-1 santa-btn" style="list-style-type: none" v-if="userData.secret_santa_game == 'true'">
        <b-avatar square style="background-color: transparent;" v-b-tooltip.hover.v-dark
        title="Secret Santa Program"
        :to="{ name: 'secret-santa' }"
  
        v-if="$can('secret_santa_access') || $can('Zircly Admin')"
      :src="require('@/assets/images/secret-santa/santa-btn.svg')"
      class="w-auto"
    />
      </b-nav-item>
      <b-nav-item style="list-style-type: none" @click="OpenDoc" class="d-flex align-items-center justify-content-center">
        <feather-icon size="21" icon="BookOpenIcon" stroke="#6E6B7B" v-b-tooltip.hover.v-dark
        title="Documentation" />
      </b-nav-item>
      
      <b-nav-item style="list-style-type: none" class="new-icon d-flex align-items-center justify-content-center">
        <feather-icon size="21" icon="MonitorIcon" stroke="#6E6B7B" v-b-tooltip.hover.v-dark v-b-modal.whats-new-model
          v-if="release_notes && turnBadgeOff >= new Date()"
          title="What's New"
        >
        </feather-icon>
        <feather-icon size="21" icon="MonitorIcon"  stroke="#6E6B7B" v-b-tooltip.hover.v-dark v-b-modal.whats-new-model
          v-else
          title="What's New"
        />
        <b-img-lazy :src="require('@/assets/images/icons/newicon.png')" alt="whats-new" width="18"/>
      </b-nav-item>
      <b-modal
        id="whats-new-model"
        hide-footer
        centered
        size="lg"
        title="What's New"
      >
        <div>
          <b-carousel
            id="whats_new"
            ref="whats_new"
            :interval="5000"
            controls
          >
            <b-carousel-slide v-for="feature in release_notes.features" :key="feature.name">
              <template #img>
                <b-card
                  :img-src="require('@/assets/images/whats_new/'+feature.img_src)"
                  img-bottom
                  img-alt="card img"
                  :title="feature.name"
                  class="mb-3"
                  @click="openImage(require('@/assets/images/whats_new/'+feature.img_src))"
                >
                  <b-card-text>
                    {{feature.description}}
                  </b-card-text>
                </b-card>
              </template>
            </b-carousel-slide>
          </b-carousel>
      
          <!-- programmatically  -->
          <!-- <div class="d-flex align-items-center justify-content-center mt-1">
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="prev"
              >
                Pre
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="start"
              >
                {{ status ? 'Start' : 'Pause' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="next"
              >
                Next
              </b-button>
            </b-button-group>
          </div> -->
        </div>
      </b-modal>
    </div>
    <!-- <b-dropdown
      id="dropdown-1"
      variant="success"
      class="mr-1"
      v-if="
        $can('hire_request_create') ||
        $can('employee_create') ||
        $can('candidate_create') ||
        $can('checklist_create') ||
        $can('holiday_create') ||
        $can('shift_create') ||
        $can('vacancy_create')
      "
    >
      <template #button-content>
        <span class="mr-75 d-none d-md-inline-block">Add New</span>
        <span><feather-icon size="16" icon="PlusIcon" class="" /></span>
      </template>
      <b-dropdown-item
        :to="{ name: 'add-nhr' }"
        v-if="$can('hire_request_create')"
        >Hire Request</b-dropdown-item
      >
      <b-dropdown-item
        :to="{ name: 'add-employee' }"
        v-if="$can('employee_create')"
        >Employee</b-dropdown-item
      >
      <b-dropdown-item
        :to="{ name: 'add-candidate' }"
        v-if="$can('candidate_create')"
        >Candidate</b-dropdown-item
      >
      <b-dropdown-item
        :to="{ name: 'add-checklist' }"
        v-if="$can('checklist_create')"
        >Checklist</b-dropdown-item
      >
      <b-dropdown-item :to="{ name: 'add-job' }" v-if="$can('vacancy_create')"
        >Job Openings</b-dropdown-item
      >
      <b-dropdown-item :to="{ name: 'add-shift' }" v-if="$can('shift_create')"
        >Shift</b-dropdown-item
      >
      <b-dropdown-item
        :to="{ name: 'add-holiday' }"
        v-if="$can('holiday_create')"
        >Holiday</b-dropdown-item
      >
    </b-dropdown>
    <b-dropdown
      id="dropdown-2"
      variant="outline-success"
      class="mr-1"
      v-if="
        $can('compensatoryOffApplication_create') ||
        $can('regularization_create') ||
        $can('leave_application_create')
      "
    >
      <template #button-content>
        <span class="mr-75 d-none d-md-inline-block">Apply For</span>
        <span><feather-icon size="16" icon="FilePlusIcon" class="" /></span>
      </template>
      <b-dropdown-item
        :to="{ name: 'add-regularize-application' }"
        v-if="$can('regularization_create')"
        >Regularization</b-dropdown-item
      >
      <b-dropdown-item
        :to="{ name: 'add-leave-application' }"
        v-if="$can('leave_application_create')"
        >Leave</b-dropdown-item
      >
      <b-dropdown-item
        :to="{ name: 'add-compensatory-off-application' }"
        v-if="$can('compensatoryOffApplication_create')"
        >Comp Off</b-dropdown-item
      >
    </b-dropdown> -->
    <b-navbar-nav class="nav align-items-center">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.first_name }}
            </p>
            <!-- <span class="user-status"> {{ userData.designation }}</span> -->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userData.avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item disabled
        >
        <b-badge variant="light-primary" v-if="userData.shift_details">
          <span class="mb-1">{{ userData.shift_details.name }}</span
            ><br />
            <span class="">
              ({{ userData.shift_details.session_1_start_from | formatTime }} -
              {{ userData.shift_details.session_2_end_at | formatTime }})
            </span>
        </b-badge>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{
          name: 'employee-profile',
          params: { id: userData.hashid },
        }"
        target='_blank'
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>
        <!-- Mobile View -->
        <b-dropdown-item
          link-class="d-flex align-items-center d-md-none"
          style="list-style-type: none" @click="OpenDoc"
        >
          <feather-icon size="21" icon="BookOpenIcon" title="Documentation" class="mr-50" />
          <span>Documentation</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center d-md-none" v-b-modal.whats-new-model>
          <span class="new-icon mr-50">
            <feather-icon size="21" icon="MonitorIcon" v-if="release_notes && turnBadgeOff >= new Date()" title="What's New" class="mr-50" />
            <feather-icon size="21" icon="MonitorIcon" v-b-modal.whats-new-model v-else title="What's New"/>
            <b-img-lazy :src="require('@/assets/images/icons/newicon.png')" alt="whats-new" width="18"/>
          </span>
            <span>What's New</span>
        </b-dropdown-item>
        <!-- End Mobile View -->
        <b-dropdown-item v-if="$can('note_access')"
          link-class="d-flex align-items-center"
          :to="{ name: 'my-notes' }"
        >
          <feather-icon size="16" icon="ClipboardIcon" class="mr-50" />
          <span>Notes</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center" @click="skin = isDark ? 'light' : 'dark'"
        >
        <feather-icon
          size="16"
          :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" class="mr-50" 
        />
          <span>Switch Theme</span>
        </b-dropdown-item>
        
        <!-- Secret Santa -->
        
        <b-dropdown-item
          link-class="d-flex align-items-center d-md-none pl-50 py-25 mobile-santa"
          style="list-style-type: none" v-if="userData.secret_santa_game == 'true'"
        >
        <div class="mr-1 santa-btn" style="list-style-type: none">
              <b-avatar square style="background-color: transparent;" v-b-tooltip.hover.v-dark
              title="Secret Santa Program"
              :to="{ name: 'secret-santa' }"
        
              v-if="$can('secret_santa_access') || $can('Zircly Admin')"
            :src="require('@/assets/images/secret-santa/santa-mobile.svg')"
            class="w-auto"
          />
        </div>
        </b-dropdown-item>
        <!-- End Secret Santa-->
        <!--<b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>-->

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-modal
      id="search-options"
      ref="search-options"
      title="Search And Quick Links"
      :hide-footer='true'
      hide-backdrop
      size="lg"
      :modal-class="myclass"
      @close="CloseModel()"
    >
      
      <b-card-text v-if="searchResult.length > 0" class="mt-1">
        <h5>Search Results</h5>
        <b-list-group flush >
          <b-list-group flush>
            <b-list-group-item v-for="Link in searchResult" :key="Link.route" v-if="$can(Link.gate)"> <feather-icon icon="StarIcon" 
              :class="isStarred(Link) ? 'text-primary' : ''" :id="Link.route" style="margin-top:-5px;margin-right:5px;"/><a @click="goTo(Link.route)">{{Link.label}}</a></b-list-group-item>
          </b-list-group>
        </b-list-group>
      </b-card-text>
      <b-tabs>
        <b-tab title="All" active>
          <b-row>
            <b-col lg="4" v-for="(LinkSet,LinkSetName) in QuickLinks" :key="LinkSetName">
              <h5 class="text-primary">{{LinkSetName}}</h5>
              <b-list-group flush>
                <b-list-group-item v-for="(Link,k) in LinkSet" :key="Link.route" v-if="$can(Link.gate)"> <feather-icon icon="StarIcon" 
                  :class="isStarred(Link) ? 'text-primary' : ''" :id="Link.route" style="margin-top:-5px;margin-right:5px;" @click="starred(LinkSetName,k)"/><a @click="goTo(Link.route)">{{Link.label}}</a></b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>          
        </b-tab>
        <b-tab
          title="Starred"
        >
        <b-row>
          <b-col>
            <b-list-group flush>
              <b-list-group-item v-for="route in userData.starred_routes" :key="route.label"> <feather-icon class="text-primary" icon="StarIcon" style="margin-top:-5px;margin-right:5px;"  /> <a @click="goTo(route.route)" > {{route.label}}</a> </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>   
      </b-tab>
      </b-tabs>
    </b-modal>
  </div>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BNavItem,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { avatarText } from "@core/utils/filter";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
import store from "@/store";
import { bus } from "@/main.js";
import $ from 'jquery';
import axios from "@axios";

Vue.use(VueCookies);
import Ripple from 'vue-ripple-directive'

import whats_new from "@/assets/jsons/whats_new.json"

import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BNavItem,

    // Navbar Components
    DarkToggler,
    BCarousel,
    BCarouselSlide,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
  data() {
    return {
      userData: this.$cookies.get("userData"),
      avatarText,
      interval: null,
      time: null,
      options: null,
      show:true,
      myclass: ['myclass'],
      QuickLinks:{
        'Core Actions' : [
          {
            'label' : 'Add Employee',
            'route' : 'add-employee',
            'gate' : 'employee_create',
          },
          {
            'label' : 'Add Candidate',
            'route' : 'add-candidate',
            'gate' : 'candidate_create'
          },
          {
            'label' : 'Hire A Star(Refer)',
            'route' : 'my-referrals',
            'gate' : 'referral_access'
          },
          {
            'label' : 'Interviews',
            'route' : 'all-interviews',
            'gate' : 'interview_access'
          },
          {
            'label' : 'Meetings',
            'route' : 'meetings',
            'gate' : 'meeting_access'
          },
          {
            'label' : 'My Notes',
            'route' : 'my-notes',
            'gate' : 'note_access'
          },
          {
            'label': "Settings",
            'route': "company-settings",
            'gate': "settings_access"
          }
        ],
        'Apply/Review':[
          {
            'label' : 'Leave Applications',
            'route' : 'leave-applications',
            'gate' : 'leave_application_access'
          },
          {
            'label': "Comp Off Applications",
            'route': "compensatory-off-applications",
            'gate': "compensatoryOffApplication_access"
          },
          {
            'label': "Regularization",
            'route': "regularize-applications",
            'gate': "regularization_access"
          },
          {
            'label': "OKR",
            'route': "okrs",
            'gate': "okr_access"
          }
        ],
        'Others':[
          {
            'label': "Holidays",
            'route': "holiday-calendar",
            'gate': "holiday_show"
          },{
            'label': "My Attendance Info",
            'route': "attendance-info",
            'gate': "attendance_show"
          },{
            'label': "Email Notifications",
            'route': "email-subscription",
            'gate': "email_notification_subscription_edit"
          },
          {
            'label': "Create Suport Ticket",
            'route': "add-ticket",
            'gate': "ticket_access"
          },
        ]
      },
      communityRoutes : ['home','draft-posts','scheduled-posts','saved-posts','community'],
      searchQuery:'',
      showSuggestions: false,
      searchResult:[],
      status: false,
      release_notes:[],
      turnBadgeOff:new Date('2023-04-05'),
      search_on:"common",
      userData: this.$cookies.get("userData"),
      mode: this.$cookies.get("userData") ? this.$cookies.get("userData").application_mode : 'Company'
    };
  },
  // mounted() {
  //   // Set up the listener for the postsLoaded event once
  //   bus.$on('postsLoaded', (posts) => {
  //     if (this.searchQuery.length > 0) {
  //       this.searchResult = posts;
  //       this.showSuggestions = this.searchResult.length > 0;
  //     }
  //     // Handle the posts data as needed
  //   });
  // },
  beforeDestroy() {
    // prevent memory leak
    // bus.$off('postsLoaded');
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
      this.release_notes = whats_new;
      const urlParams = new URLSearchParams(window.location.search);
   

    bus.$on("search_on", (search_on = "common") => {
      this.search_on = search_on;
      if(urlParams.get('q')){
        this.searchQuery = urlParams.get('q');
        this.onSearch(this.searchQuery,false);
      }else{
        this.onSearch(this.searchQuery);
      }

    });
  },
  computed:{
   
  },
  watch: {
    // searchQuery(val) {
    //   this.updateQuries(val);    
    // }
  },
  methods: {
    handleDown(event) {
      let currentRoute = this.$route.name;
        if (this.communityRoutes.includes(currentRoute)) {
            const trimmedStr = this.searchQuery.trim();
            this.search(trimmedStr, "post");
        }else{
          this.$toast({
              component: ToastificationContent,
              props: {
                title: "Choose Module to Search",
                icon: "SearchIcon",
                variant: "info",
                text: "Please choose module from options to search",
              },
            });
        }
    },
    openImage(path){
      window.open(path, '_blank');
    },
    openURL(){
      var re = new RegExp(/^.*\//);
      window.open(re.exec(window.location.href)+"map-view", '_blank');
    },
    getSource(src){
      return require(src);
    },
    prev() {
      if (!this.status) {
        this.$refs.whats_thenew.prev()
      }
    },
    next() {
      if (!this.status) {
        this.$refs.whats_new.next()
      }
    },
    start() {
      this.status = !this.status
      if (this.status) {
        this.$refs.whats_new.pause()
      } else {
        this.$refs.whats_new.start()
      }
    },
    logout() {
      this.$cookies.remove("userData");
      this.$cookies.remove("accessToken");
      if (!useJwt.getToken()) {
        this.$router.push({ name: "auth-login" });
      }
      // Remove userData from localStorage
      useJwt.logout().then((res) => {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
        // Remove userData from localStorage
        localStorage.removeItem("userData");
        localStorage.removeItem("hasRedirect");
        localStorage.removeItem("EncryptedAbilities");
        // localStorage.clear()
        // Reset ability
        this.$ability.update(initialAbility);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Sign Out",
            icon: "BellIcon",
            text: res.data.message,
            variant: "success",
          },
        });
        // Redirect to login page
        this.$router.push({ name: "auth-login" });
      });
    },
  
    OpenDoc(){
      window.open("https://docs.zircly.com", "_blank");
    },
    OpenModel(){
      this.$refs['search-options'].show();
      $('#search-link').focus();
      
    },
    goTo(route){
      // Redirect to page
      this.$router.push({ name:route });
    },
    starred(setName,key){
      var type = 'add';
      if ($( "#"+this.QuickLinks[setName][key].route).hasClass('text-primary')) {
        $(  "#"+this.QuickLinks[setName][key].route).removeClass( 'text-primary');
        type = 'remove';
      } else {
        $(  "#"+this.QuickLinks[setName][key].route).addClass( 'text-primary');
      }
      const self = this;
      const formData = new FormData();
      formData.append("starred", JSON.stringify(this.QuickLinks[setName][key]));
      let data = {};
      data.url = "starred-route/"+type;
      data.params = formData;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.userData.starred_routes = res.data.starred_routes;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Route "+type+"ed",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Unable to "+type+" the Route",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    isStarred(link){
      if(this.userData.starred_routes.length > 0){
        for ( var key in this.userData.starred_routes) {
          if(this.userData.starred_routes[key].label == link.label){
            return true;
          }
        }
      }
      return false;
    },
    search(str, type) {
  const trimmedStr = str.trim();
  const queryParams = { q: trimmedStr, type: type };
  if (type == 'post') {
    bus.$emit("AddFilter",'community');
    
      this.$router.push({ 
        path: '/community',
        query: { 
          q: trimmedStr,
          type: "community"
        }
      });
      this.searchQuery  = trimmedStr;

    // .then(()=>{
    //   bus.$emit("loadPosts",trimmedStr);
    // });
    // this.searchQuery = ""
  } else if (type == 'event') {
    bus.$emit("AddFilter",type);
    this.$router.push({ path: '/community', query: queryParams })
    .then(() => {
      bus.$emit("loadEvents", { q: trimmedStr, type: type });
    })
    // this.searchQuery = ""
  } 
  else if (type == 'poll') {
    bus.$emit("AddFilter",type);
    bus.$emit("loadPolls",trimmedStr);
    this.$router.push({ path: '/community', query: queryParams });
    // this.searchQuery = ""
  } 
  else if (type == 'employee') {
    this.$router.push({ path: '/employees', query: { q: trimmedStr } });
    // this.searchQuery = ""
  } else if (type == 'okr') {
    this.$router.push({ path: '/list/all-okrs', query: { q: trimmedStr } });
    // this.searchQuery = ""
  } else if (type == 'profile') {
     if (this.employeeData) {
      window.open(
          `/profile/${this.employeeData.hashid}`,
          "_blank"
      );
    }
  } else if (type == 'member') {
    this.$router.push({ path: '/members', query: { q: trimmedStr } });
  } else if (type == 'candidate') {
    this.$router.push({ path: '/candidates', query: { q: trimmedStr } });
  }
  this.show = false;
},

    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },

    async onSearch(search_str,show = true){
      this.show = show;
      const query = search_str.trim();
      if (query.length === 0 ) {
        this.searchResult = [];
        return;
      }
      const baseSuggestions = [
        { text: `Search "${query}" in Post`, type: 'post' },
        { text: `Search "${query}" in Events`, type: 'event' },
        { text: `Search "${query}" in Poll`, type: 'poll' },
      ];

     
      if (this.mode === 'company' && !this.isValidEmail(query)) {
        this.searchResult = [
          ...baseSuggestions,
          { text: `Search "${query}" in Employee`, type: 'employee' },
          { text: `Search "${query}" in Candidate`, type: 'candidate' },
          { text: `Search "${query}" in OKR`, type: 'okr' },
        ];
      } 
      else if (this.mode === 'community' && !this.isValidEmail(query)) {
        this.searchResult = [
          ...baseSuggestions,
          { text: `Search "${query}" in Member`, type: 'member' },
        ];
      }

      else if(this.isValidEmail(query)){
        if(await this.getSearchUser(query)){
        this.searchResult = [
          { text: `${this.employeeData.name}'s Zircly Profile`, type: 'profile' },
          { text: `Post By ${this.employeeData.name} (${this.employeeData.email})`, type: 'post' },
          { text: `Events By ${this.employeeData.name} (${this.employeeData.email})`, type: 'event' },
          { text: `Polls By ${this.employeeData.name} (${this.employeeData.email})`, type: 'poll' },
          { text: `OKR By  ${this.employeeData.name} (${this.employeeData.email})`, type: 'okr' },
        ];
        }
      }





      // if(this.search_on == 'post'){
      //   bus.$emit("loadPosts",search_str);
      // }
      // else if(this.search_on == 'event'){
      // bus.$emit("loadEvents", { q: search_str });
      // }
      // else if(this.search_on == 'poll'){
      //   alert('ji')
      //   bus.$emit("loadPolls",search_str);
      // }
      // else{
      //   var suggestions = [];
      //   console.log(suggestions);
      //   for(var set in this.QuickLinks){
      //     for(var route in this.QuickLinks[set]){
      //       console.log(this.QuickLinks[set][route].label.indexOf(search_str) > -1);
      //       if (this.QuickLinks[set][route].label.toUpperCase().indexOf(search_str.toUpperCase()) > -1)
      //       { 
      //         suggestions.push(this.QuickLinks[set][route]);
      //       }
      //     }
      //   }
      //   console.log(suggestions.length);
      //   this.searchResult = suggestions;
      // }
    },

getSearchUser(query) {
  return axios.get(`/search-user`, {
    params: {
      email: query
    }
  })
  .then((res) => {
    if (res.data.success) {
      this.employeeData = res.data.user;
      return true;
    } else {
      return false;
    }
  })
  .catch((error) => {
    return false;
  });
    },

    
    suggestionSelected(suggestion){
        this.$router.push({ query: { post_hash_id: suggestion.hashid } });
    },
    CloseModel() {

      let currentURL = this.$route.fullPath;
      if(this.$route.path != currentURL){
        this.$router.push({ path: this.$route.path });
      }

      // Clear the search query and emit events
      this.searchQuery = '';
      this.show = false;
      this.searchResult = [];

      
      bus.$emit("clearGlobalSearch");
    }

    },
  };
</script>
<style>
  .myclass > div {
    position:absolute !important;
    top: 7% !important;
    left:28%!important;
    width: 100%;
  }
  
  .myclass > .modal-dialog > .modal-content {
  }
  .suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 1000;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}
</style>
