export default [
    {
        title: "Community",
        route: "home",
        icon: "GridIcon",
        resource: "Community",
        action: "read",
        children:[
            {
                title: "Drafts",
                route: "draft-posts",
                resource: "Posts",
                gate: "post_access"
            },
            {
                title: "Scheduled Posts",
                route: "scheduled-posts",
                resource: "Posts",
                gate: "post_access"
            },
            {
                title: "Saved Posts",
                route: "saved-posts",
                resource: "Posts",
                gate: "post_access"
            },
        ],
        gate: [
            "poll_access",
            "event_access",
            "post_access",
            "secret_santa_access"
        ]
    },
    {
        title: "Dashboard",
        route: "dashboard",
        icon: "LayoutIcon",
        resource: "Dashboard",
        action: "read",
        gate: "dashboard_access"
    },
    

    {
        title: "Insider Program",
        icon: "AwardIcon",
        resource: "Karma Program",
        gate: ["karma_program_access","karma_category_access",'karma_catalog_access','karma_request_access'],
        children: [
            {
                title: "Karma Category",
                route: "karma-categories",
                resource: "Karma Category",
                gate: "karma_category_access"
            },
            {
                title: "Karma Catalog",
                route: "list-karma-catalogs",
                resource: "Karma Catalog",
                gate: "karma_catalog_access"
            },
            {
                title: "Karma Program",
                resource: "Karma Program",
                route: "karma-programs",
                gate: "karma_program_access"
            },
            {
                title: "Karma Leaderboard",
                resource: "Karma Leaderboard",
                route: "karma-leaderboard",
                gate: "karma_request_access"
            },
            {
                title: "Karma Request",
                resource: "Karma Request",
                route: "karma-requests",
                gate: "karma_request_access"
            },
        ]
    },

    // {
    //     title: "OKR",
    //     resource: "OKR",
    //     icon: "TargetIcon",
    //     gate: "Debug",
    //     children: [
    //         {
    //             title: "My OKRs",
    //             route: "individual-okrs",
    //             resource: "My OKR",
    //             gate: "okr_access"
    //         },
    //         {
    //             title: "Team OKRs",
    //             route: "team-okrs",
    //             resource: "Team OKRs",
    //             gate: "okr_access"
    //         },
    //         {
    //             title: "OKR Manager",
    //             route: "okrs",
    //             resource: "OLD OKR",
    //             gate: "Zircly Admin"
    //         }
    //     ]
    // },

    {
        title: "OKR",
        resource: "OKR",
        icon: "TargetIcon",
        gate: ["okr_access","okr_create","Organisation OKR Manager"],
        children: [
            {
                title: "My OKRs",
                // href:'/list/my-okrs',
                route:'my-okrs',
                target:null,
                resource:'OKR',
                gate: "okr_access"
            },
            {
                title: "All OKRs",
                route:'all-okrs',
                // href:'/list/all-okrs',
                target:null,
                resource:'OKR',
                gate: "okr_access"
            },
            {
                title: "Add New OKR",
                resource: "Create OKR",
                href:'/create-okr/individual-okr',
                gate: "okr_create"
            },
            {
                title: "Add Team OKR",
                href:'/create-okr/team-okr',
                resource: "Create OKR",
                gate: "reporting_manager"
            },
            {
                route:'review-okrs',
                title: "Review OKRs",
                // href:'/list/review-okrs',
                target:null,
                resource:'OKR',
                gate: "reporting_manager"
            },
            {
                title: "Add Organisation OKR",
                href:'/create-okr/organisation-okr',
                resource: "Create OKR",
                gates: ["okr_create","Organisation OKR Manager"]
            }
        ]
    },

    {
        title: "Attendance",
        icon: "CheckSquareIcon",
        gate: ["attendance_show"],
        children: [
            {
                title: "Attendance Info",
                route: "attendance-info",
                resource: "Attendance",
                gate: "attendance_show"
            },

            {
                title: "Regularizations Applications",
                resource: "Regularize Applications",
                route: "regularize-applications",
                gate: "regularization_access"
            },

            {
                title: "Attendance Muster",
                route: "attendance-muster",
                resource: "Attendance Muster",
                gates: ["Zircly Admin","attencdance_muster_access"]
            }
        ]
    },

    {
        title: "Leave",
        icon: "CalendarIcon",
        gate: [
            "leave_application_access",
            "compensatoryOffApplication_access",
            "regularization_access",
            "employee_leave_access",
            "holiday_show"
        ],
        children: [
            {
                title: "Leaves",
                route: "leaves",
                resource: "Leaves",
                gates: ["Zircly Admin","leave_access"]
            },
            {
                title: "Employee Leaves Balance",
                route: "employee-leaves",
                resource: "Employee Leaves Balance",
                gate: "employee_leave_access"
            },
            {
                title: "Leave Applications",
                route: "leave-applications",
                resource: "Leave Applications",
                gate: "leave_application_access"
            },
            {
                title: "Comp Off Applications",
                resource: "Comp Off Applications",
                route: "compensatory-off-applications",
                gate: "compensatoryOffApplication_access"
            },
            {
                title: "Holiday Manager",
                resource: "Holidays",
                route: "holidays",
                gates: ["Zircly Admin","holiday_access"]
            },
            {
                title: "Holiday Calendar",
                resource: "Holiday Calendar",
                route: "holiday-calendar",
                gate: "holiday_show"
            }
        ]
    },
    {
        title: "Company",
        icon: "MonitorIcon",
        gate: ["meeting_access", "company_tree_access"],
        children: [
            {
                title: "Resources",
                resource: "Resources",
                route: "quick-links",
                gate: "resource_access"
            },
            {
                title: "People",
                route: "people",
                resource: "People",
                gate: "people_access"
            },
            {
                title: "Company Tree",
                resource: "Company Tree",
                route: "org-chart",
                gate: "company_tree_access"
            },
            {
                title: "Meeting",
                resource: "Meeting",
                route: "meetings",
                gate: "meeting_access"
            },
            {
                title: "Calendar",
                resource: "Calendar",
                route:'calendar',
                gate: "calendar_access"
            },
            {
                title: "Map View",
                resource: "Map View",
                route:"map-view",
                gate: "map_view_access"
            }
        ]
    },
    {
        title: "Help",
        icon: "HelpCircleIcon",
        resource: "Ticket",
        action: "read",
        gate:  ["company_access"],
        children: [
            {
                title: "Zircly Support Ticket",
                resource: "Tickets",
                route: "tickets",
                gate: "Public"
            },
            {
                title: "Faq",
                resource: "Faq",
                route: "faqs",
                gate: "faq_access"
            },
            {
                title: "Faq List",
                resource: "FAQ",
                route: "list-faq",
                gates: ["Zircly Admin","faq_access"]
            },
        ]
    },
    {
        title: "Recruitment",
        icon: "UserPlusIcon",
        resource: "Employee",
        action: "read",
        gate: [
            "hire_request_access",
            "interview_access",
            "candidate_access",
            "vacancy_access",
            "referral_access"
        ],
        children: [
            {
                title: "Hire Request",
                route: "all-nhr-list",
                resource: "Hire Request",
                action: "read",
                gate: "hire_request_access"
            },
            {
                title: "Interviews",
                route: "all-interviews",
                resource: "Interview",
                gate: "interview_access"
            },
            {
                title: "Candidates",
                resource: "Candidate",
                route: "candidates",
                action: "read",
                gate: "candidate_access"
            },
            {
                title: "Job Openings",
                resource: "Vacancy",
                route: "jobs",
                gate: "vacancy_access"
            },
            {
                title: "Hire A Star",
                route: "my-referrals",
                resource: "Referral",
                action: "read",
                gate: "referral_access"
            },
            {
                title: "Direct Applications",
                route: "direct-applications",
                resource: "Direct Application",
                action: "read",
                gate: "candidate_access"
            }
        ]
    },
    {
        title: "Members",
        icon: "UserIcon",
        resource: "Employee",
        action: "read",
        gate: ['community_access'],
        route: "members",
    },
    {
        title: "Chats",
        route: "chats",
        icon: "MessageSquareIcon",
        resource: "Chats",
        action: "read",
        gate: "chat_access"
    },
    {
        title: "Employees",
        icon: "UsersIcon",
        resource: "Employee",        
        action: "read",
        gate: ['employee_access','checklist_access','update_profile_request_access'],
        gates:['company_access'],
        children: [
            {
                title: "Employees List",
                route: "employees",
                resource: "Employee",
                gates: ["employee_access","company_access"]
            },
            {
                title: "Manage Checklist",
                route: "manage-checklist",
                resource: "Checklist",
                gate: "checklist_access"
            },
            {
                title: "Update review",
                resource: "UpdateProfileRequest",
                route: "update-profile-requests",
                gate: "update_profile_request_access",
            },
            {
                title: "Shifts",
                resource: "Shift",
                route: "shifts",
                gate: "shift_access"
            }
        ]
    },
    
    {
        title: "Email Subscription",
        resource: "Email Subscription",
        route: "email-subscription",
        icon: "MailIcon",
        gate: "email_notification_subscription_edit"
    },
    
    {
        title: "Settings",
        icon: "SettingsIcon",

        gate: ["settings_access", 'role_access', "email_notification_access", "api_key_access", "voice_call_log_access","login_logs_access"],
        children: [
            
            {
                title: "Import",
                resource: "Import",
                route: "imports",
                gate: "import_access"
            },
            {
                title: "Export",
                resource: "Export",
                route: "exports",
                gates: ["export_access","company_access"]
            },
            {
                title: "Company Settings",
                resource: "Company Settings",
                route: "company-settings",
                gates: ["settings_access","company_access"]
            },
            {
                title: "Settings",
                resource: "Settings",
                route: "settings",
                gate: "settings_access"
            },
            {
                title: "Role and Permissions",
                resource: "Role and Permissions",
                route: "role-permission",
                gate: "role_access"
            },
            {
                title: "Custom Fields",
                resource: "Custom Fields",
                route: "custom-fields",
                gates: ["Zircly Admin","custom_fields_access"]
            },
            {
                title: "Email Notifications",
                resource: "Email Notification",
                route: "email-notifications",
                gate: "email_notification_access"
            },
            {
                title: "API Keys",
                resource: "API Keys",
                route: "api-keys",
                gate: "api_key_access"
            },
            {
                title: "Voice Call log",
                resource: "Voice Call Log",
                route: "voice-call-logs",
                gate: "voice_call_log_access"
            },
            {
                title: "Login logs",
                resource: "Login Logs",
                route: "login-logs",
                gate: "login_logs_access"
            }

        ]
    }
];
