var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
    {
      expanded: (_vm.sideBar) || (!_vm.sideBar && _vm.isMouseHovered)
    },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded"},[_vm._t("header",[_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[_c('b-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('span',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"src":_vm.skin == 'light'  ? (_vm.userData.light_logo 
        ? _vm.userData.light_logo 
        : (_vm.appLogoLightImage 
            ? _vm.appLogoLightImage 
            : _vm.userData.dark_logo))
    : (_vm.userData.dark_logo 
        ? _vm.userData.dark_logo 
        : _vm.appLogoDarkImage),"alt":"logo"}})],1),_c('b-img',{staticClass:"brand-text",staticStyle:{"width":"100%"},attrs:{"src":_vm.skin == 'light' ? (_vm.userData.light_name_logo 
        ? _vm.userData.light_name_logo 
        : (_vm.appNameLightLogo 
            ? _vm.appNameLightLogo 
            : _vm.userData.dark_name_logo))
    : (_vm.userData.dark_name_logo 
        ? _vm.userData.dark_name_logo 
        : _vm.appNameDarkLogo),"alt":"logo"}})],1)],1),_c('li',{staticClass:"nav-item nav-toggle"},[_c('b-link',{staticClass:"nav-link modern-nav-toggle"},[_c('feather-icon',{staticClass:"d-block d-xl-none",attrs:{"icon":"XIcon","size":"20"},on:{"click":_vm.toggleVerticalMenuActive}}),_c('feather-icon',{staticClass:"d-none d-xl-block collapse-toggle-icon",staticStyle:{"margin-top":"35px"},attrs:{"icon":_vm.collapseTogglerIconFeather,"size":"20"},on:{"click":_vm.toggleCollapsed}})],1)],1)])],{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }}},[_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main",attrs:{"items":_vm.navMenuItems}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }